import React, {Fragment} from "react";
import Banner from "../../components/Banner/Banner";
import HowPharmatchingWork from "../../components/HowPharmatchingWork/HowPharmatchingWork";
import GeneralStatistics from "../../components/GeneralStatistics/GeneralStatistics";
import TopCategories from "../../components/TopCategories/TopCategories";
import FastAndEfficient from "../../components/FastAndEfficient/FastAndEfficient";
import WorldAccess from "../../components/WorldAccess/WorldAccess";
import HomeLatestNews from "../../components/HomeLatestNews/HomeLatestNews";
import HomeWarningLetters from "../../components/HomeWarningLetters/HomeWarningLetters";
import NeedHelp from "../../components/NeedHelp/NeedHelp";
import Appointment from "../../components/Appointment/Appointment";
import ServiceCategoryCarousel from "../../components/ServiceCategoryCarousel/ServiceCategoryCarousel";
import TrustPilot from "../../components/TrustPilot/TrustPilot";
import Partnership from "../../components/Partnership/Partnership";
import ScrollButton from "../../components/ScrollButton/ScrollButton";

const Home = () => {
    return (
        <Fragment>
            <Banner/>
            <ServiceCategoryCarousel/>
            <WorldAccess/>
            <HowPharmatchingWork/>
            <TrustPilot/>
            <Partnership/>
            <Appointment
                title={"Book your FREE 30 min demo call with us."}
                link={"https://outlook.office365.com/owa/calendar/Pharmatching@zamann-pharma.com/bookings/s/CxjqsLDxmEKrkchtNX5qSw2"}
                text={"Take the first step towards optimized supplier outsourcing & project tendering and book a free demo with us today."}
                buttonText={"Book a FREE demo"}
            />
            <GeneralStatistics/>
            <HomeWarningLetters/>
            <HomeLatestNews/>
            <NeedHelp/>
            <ScrollButton/>
            {/* {isLoggedIn ? (
				<Fragment>
					<Banner />
					<TopCategories />
					<HomeNewsLetterSection />
					<HomePharmWizard />
				</Fragment>
			) : (
				<Fragment>
					<Banner />
					<FastAndEfficient />
					<WorldAccess />
					<HowPharmatchingWork />
					<GeneralStatistics />
					<HomeWarningLetters />
					<HomeLatestNews />
					<TopCategories />
					<NeedHelp />
				</Fragment>
			)} */}
        </Fragment>
    );
};

export default Home;
