import classes from "./Partnership.module.scss";
import {API_TAGS_URL} from "../../constants/API";
import useHttp from "../../hooks/use-http";
import React, {useEffect, useCallback, useState, Fragment} from "react";
import Slider from "react-slick";

const Partnership = () => {
    const {sendRequest, isLoading} = useHttp();
    const [partnerships, setPartnerships] = useState(null);
    const getPartnerships = (partnerships) => {
        setPartnerships(partnerships);
    };
    const fetchPartnerships = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/partnership/`,
            },
            getPartnerships
        );
    }, [sendRequest]);

    useEffect(() => {
        fetchPartnerships();
        return () => {
            setPartnerships([]); // This worked for me
        };
    }, [fetchPartnerships]);
    const slidesToShow = partnerships && partnerships.length > 3 ? 3 : partnerships && partnerships.length;
    const SampleNextArrow = (props) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={classes['nextBtn']}
                style={{...style}}
                onClick={onClick}
            />
        );
    }

    const SamplePrevArrow = (props) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={classes['prevBtn']}
                style={{...style}}
                onClick={onClick}
            />
        );
    }
    const settings = {
        lazyLoad: true,
        infinite: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]

    };
    return (
        <section className={`${classes["partnership"]}`}>
            <div className="container">
                <p className={`${classes["partnership-title"]}`}>Partnership with</p>
                <Slider {...settings}>
                    {partnerships && partnerships.map((p, index) => (
                        <div key={p.id} className={classes['partner']}>
                            {
                                p.link ? <a href={p.link} target="_blank">
                                        <img src={p.icon} alt={p.title}/>
                                        <h3>{p.title}</h3>
                                    </a> :
                                    <Fragment>
                                        <img src={p.icon} alt={p.title}/>
                                        <h3>{p.title}</h3>
                                    </Fragment>
                            }


                        </div>
                    ))}
                </Slider>

            </div>
        </section>
    );
};

export default Partnership;
