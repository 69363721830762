import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./CreateApplication.module.scss";
import ConfirmModal from "../../components/UI/ConfirmModal/ConfirmModal";
import RequesViewSummary from "../../components/RequestViewSummary/RequstViewSummary";
import useHttp from "../../hooks/use-http";
import { API_REQUEST_URL } from "../../constants/API";
import { useHistory, useParams } from "react-router-dom";
import { getAccessToken } from "../../utils/authentication";
import CreateApplicationDetail from "../../components/CreateApplicationDetail/CreateApplicationDetail";
import { createRandomId } from "../../utils/common";
import { addToast } from "../../redux/Toast/toastAction";
import {updateIsAssetsChnage} from "../../redux/User/userAction";

const CreateApplication = () => {
    const { sendRequest, error, isLoading } = useHttp();
    const [requestInfo, setRequestInfo] = useState(null);
    const { id } = useParams();
    //-----------------Modal consts-------------------
    const [modalMessage, setModalMessage] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalButtonText, setModalButtonText] = useState(null);
    const [modalHeader, setmodalHeader] = useState(null);
    const userSubscription = useSelector((state) => state.user.userSubscription);
    const history = useHistory();
    const [activeFlag, setActiveFlag] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const dispatch = useDispatch();
    const getRequestInfo = (data) => {
        setRequestInfo(data);
    };
    const fetchRequestInfo = useCallback(() => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_REQUEST_URL}${id}`,
                headers: { Authorization: `Bearer ${token}` },
            },
            getRequestInfo
        );
    }, [id, sendRequest]);


    const onActiveFlagHandler = (activeFlag) => {
        setActiveFlag(activeFlag);
    };

    const applicationSubmit = () => {
        //const token = getAccessToken();
        setIsSubmit(true);
        dispatch(updateIsAssetsChnage(true));
    };
    const displayNoSubscriptiontToast = useCallback(() => {
        dispatch(
          addToast({
            id: createRandomId(),
            status: "error",
            description: "There are no available Applications in your Profile, but you are only 1 click away to access the global market again. We will forward you to our Solution Manager now and inform your Pharmatching Company Admins about the depleted Company Inventory.",
          })
        );
      }, [dispatch]);
    const sendApplicationHandler = (action) => {        
        if ((!userSubscription) || (userSubscription && !userSubscription.is_active))
        {
            displayNoSubscriptiontToast();
            history.push("/subscription");
        }
        else {
            applicationSubmit();
        }
    };
    //------------------------------------------------------------------------------------------
    useEffect(() => {
        fetchRequestInfo();
    }, [fetchRequestInfo]);

    //------------------------------------------------------------------------------------------
    return (
        <section
            className={`container  flex-grow position-relative ${classes.section}  ${classes["min-h"]}`}
        >
            {modalMessage && (
                <ConfirmModal
                    modalButtonText={modalButtonText}
                    modalType={modalType}
                    modalText={modalMessage}
                    modalHeader={modalHeader}
                //onConfirm={confirmUserAction}
                />
            )}
            {requestInfo && !isLoading && (
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-5 col-md-10 col-12">
                        <RequesViewSummary
                            requestInfo={requestInfo}
                            //onConfirmDelete={confirmCloseRequest}
                            onSendApplicationHandler={sendApplicationHandler}
                            isCreateApplication={true}
                            userSubscription={userSubscription}
                            activeFlag={activeFlag}
                        />
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-10 col-12">
                        <CreateApplicationDetail
                            onActiveFlagHandler={onActiveFlagHandler}
                            isSubmit = {isSubmit}
                            requestInfo = {requestInfo}
                        />
                    </div>
                </div>
            )}


        </section>
    )
}

export default CreateApplication;