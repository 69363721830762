import classes from "./CompanyInventory.module.scss";
import Card from "../UI/CardV2/CardV2";
import bagIcon from "../../assets/images/Icons/bag.svg";
import { Link } from "react-router-dom";

const CompanyInventory = ({ userSubscription, userCompany }) => {
  return (
    <Card className={classes["company-inventory-card"]}>
      <div className={classes["header-company-inventory"]}>
        {/* <img src={bagIcon} alt="bag" /> */}
        <span className={classes["header-txt"]}>Company Inventory</span>
      </div>
      <div className="d-flex justify-content-between px-5 ">
        <div className={`${classes["inventory-item"]} `}>
          {userSubscription && userSubscription.is_active && (
            <span className={classes["inventory-count"]}>
              {userSubscription.remaining_requests}
            </span>
          )}
          {userSubscription && !userSubscription.is_active && (
            <span className={classes["inventory-count"]}>0</span>
          )}
          <span className={classes["inventory-txt"]}>Request</span>
        </div>
        <div className={`${classes["inventory-item"]} `}>
          {userSubscription && userSubscription.is_active && (
            <span className={classes["inventory-count"]}>
              {userSubscription.remaining_applications}
            </span>
          )}
          {userSubscription && !userSubscription.is_active && (
            <span className={classes["inventory-count"]}>0</span>
          )}
          <span className={classes["inventory-txt"]}>Application</span>
        </div>
        <div className={`${classes["inventory-item"]} `}>
          {userSubscription && userSubscription.is_active && (
            <span className={classes["inventory-count"]}>
              {userSubscription.remaining_days}
            </span>
          )}
          {userSubscription && !userSubscription.is_active && (
            <span className={classes["inventory-count"]}>0</span>
          )}
          <span className={classes["inventory-txt"]}>Days</span>
        </div>
      </div>
      <div className={classes["solution-manager-box"]}>
        <Link className={`${classes["solution-managar"]} btn`} to="/subscription">
          Solution Manager
        </Link>
      </div>
      <div className={classes["manage-payment-box"]}>
        {userCompany && (
          <a
            className={`${classes["manage-payment"]} btn`}
            href={userCompany.stripe_portal_url}
          >
            Manage Payment
          </a>
        )}

        <Link className={`${classes["manage-payment"]} btn`} to="/dashboard/invoices">
          Payment Invoices
        </Link>
      </div>
    </Card>
  );
};

export default CompanyInventory;
