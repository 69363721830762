import { Link, useHistory } from "react-router-dom";
import classes from "./TrustPilot.module.scss";
import { API_TAGS_URL } from "../../constants/API";
import useHttp from "../../hooks/use-http";
import React, { useEffect, useCallback, useState } from "react";
import Slider from "react-slick";
import { ReactComponent as Star } from "../../assets/images/Icons/star.svg";
import { ReactComponent as BlankStar } from "../../assets/images/Icons/star_blank.svg";
import { ReactComponent as TPLogo } from "../../assets/images/logo/trustpilot.svg";


const TrustPilot = () => {
    const { sendRequest, isLoading } = useHttp();
    const [TrustPilots, setTrustPilots] = useState(null);
    const getTrustPilots = (trustPilots) => {
        setTrustPilots(trustPilots);
    };
    const fetchTrustPilots = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/trust-pilots/`,
            },
            getTrustPilots
        );
    }, [sendRequest]);

    useEffect(() => {
        fetchTrustPilots();
        return () => {
            setTrustPilots([]); // This worked for me
        };
    }, [fetchTrustPilots]);
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={classes['nextBtn']}
                style={{ ...style }}
                onClick={onClick}
            />
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={classes['prevBtn']}
                style={{ ...style }}
                onClick={onClick}
            />
        );
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Number of items to show at once
        slidesToScroll: 1, // Number of items to scroll at a time
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 2200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]

    };

    return (
        <section className={`${classes["trustpilot"]}`}>
            <div className="container-fluid">
                <h3 className={`${classes["trustpilot-title"]}`}>Trusted by Pharma, MedDev & Life Science companies</h3>
                <div className={classes["tp-logo"]}>
                    Find our user Testimonials and
                    <a target="_blank" href="https://de.trustpilot.com/review/pharmatching.com">
                        {/* <TPLogo /> */}
                        &nbsp;Trustpilot&nbsp;
                    </a>
                    feedback here:
                </div>
                <div className={classes["rating"]}>
                    <Star /> <Star /> <Star /> <Star /> <BlankStar /> &nbsp; <span>4.2 from 5</span>
                </div>

                {/* <div className={classes.detail}>
                     Find our user testimonials and @Trustpilot feedback
                </div> */}
                <Slider {...settings}>
                    {TrustPilots && TrustPilots.map((tp, index) => (
                        <div key={tp.id}>
                            <div className={classes['tp-card-container']}>
                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        {tp.icon ? <img className={classes['tp-img']} alt={tp.title} src={tp.icon} /> : <br />}
                                        <h3>{tp.title}</h3>
                                    </div>
                                    <div className='col-sm-8 col-12'>
                                        <div className={classes["text-wrapper"]}>
                                            <p>{tp.review}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

            </div>
        </section>
    );
};

export default TrustPilot;
