import classes from "./GeneralStatistics.module.scss";
import { useState, useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { API_GENERAL_URL } from "../../constants/API";
import Loading from "../UI/Loading/Loading";
import GeneralStatisticsCard from "../GeneralStatisticsCard/GeneralStatisticsCard";

import { ReactComponent as Companies } from "../../assets/images/home/companies.svg";
import { ReactComponent as MatchMaked } from "../../assets/images/home/match-make.svg";
import { ReactComponent as KeyServices } from "../../assets/images/home/key-services.svg";
import { ReactComponent as Category } from "../../assets/images/home/requests.svg";

const GeneralStatistics = () => {
	const [generalStatistics, setGeneralStatistics] = useState(null);
	const { sendRequest, isLoading } = useHttp();

	const getGeneralStatistics = (data) => {
		setGeneralStatistics(data);
	};

	useEffect(() => {
		sendRequest({ url: `${API_GENERAL_URL}home-data/` }, getGeneralStatistics);
		return () => {
			setGeneralStatistics([]);
		};
	}, [sendRequest]);
	return (
		<section className={`${classes["counter-project"]}`}>
			<div className="container">
				<h3 className={`${classes["counter-title"]}`}>
					Our performance KPIs
				</h3>
				{isLoading && <Loading className={classes.loading} />}
				{!isLoading && generalStatistics && (
					<div className="row gap-3 py-4 align-items-center justify-content-center justify-content-md-around">
						<GeneralStatisticsCard
							className='col-12 col-md-5 col-lg-2 my-4 my-lg-0'
							title="Companies"
							value={generalStatistics.companies}
						>
							<Companies />
						</GeneralStatisticsCard>
						<GeneralStatisticsCard
							className='col-12 col-md-5 col-lg-2 my-4 my-lg-0'
							title="Key Services"
							value={generalStatistics.key_services}
						>
							<KeyServices />
						</GeneralStatisticsCard>
						<GeneralStatisticsCard
							className='col-12 col-md-5 col-lg-2 my-4 my-lg-0'
							title="Categories"
							value={generalStatistics.categories}
						>
							<Category />
						</GeneralStatisticsCard>

						{/* <GeneralStatisticsCard
							className='col-12 col-md-5 col-lg-2 my-4 my-lg-0'
							title="Match Made"
							value={generalStatistics.matches}
						>
							<MatchMaked />
						</GeneralStatisticsCard> */}
					</div>
				)}
			</div>
		</section>
	);
};

export default GeneralStatistics;
