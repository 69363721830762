import {useState, useEffect} from "react";
import classes from "./WarningLetterList.module.scss";
import WarningLetterEuDramedItem from "../../components/WarningLetterEuDramedItem/WarningLetterEuDramedItem";
import HomeLetterCard from "../../components/HomeLetterCard/HomeLetterCard";
import useHttp from "../../hooks/use-http";
import {API_NEWS_URL} from "../../constants/API";
import {useHistory} from "react-router-dom";
import {
    splitQueryParams,
    updateUniqueUrlParams,
    updateUniqueApiParams,
    resetQueryParams,
} from "../../utils/QueryParams";
import calculateOffset from "../../utils/calculateOffset";
import NewsFilterBox from "../../components/NewsFilterBox/NewsFilterBox";
import SortBox from "../../components/UI/SortBox/SortBox";
import NotFoundItems from "../../components/UI/NotFoundItems/NotFoundItems";
import Loading from "../../components/UI/Loading/Loading";
import Pagination from "../../components/UI/Pagination/Pagination";
import {LIMIT_ITEM_WARNING} from '../../constants/Common';
import NewsFilterBoxAccordion from "../../components/NewsFilterBoxAccordion/NewsFilterBoxAccordion";
import NewsSearchBox from "../../components/NewsSearchBox/NewsSearchBox";


const WarningLetterList = () => {
    const [warningLetterList, setWarningLetterList] = useState([]);
    const [count, setCount] = useState(null);
    const [sortItem, setSortItem] = useState(null);
    const [sourceItem, setSourceItem] = useState(null);
    const [searchItem, setSearchItem] = useState(null);
    const [timeFilter, setTimeFilter] = useState(null);
    const [apiQueryParams, setApiQueryParams] = useState(`?limit=${LIMIT_ITEM_WARNING}`);
    const [currentPage, setCurrentPage] = useState(null);
    const {sendRequest, isLoading} = useHttp();
    const history = useHistory();

    const splitUrlQueryparams = () => {
        const queryParams = window.location.search;
        const splitedQueryParams = splitQueryParams(queryParams);
        setWarningLetterStates(splitedQueryParams);
    };

    const setWarningLetterStates = (params) => {
        params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
        params.sort ? setSortItem(params.sort) : setSortItem(null);
        params.text ? setSearchItem(params.text) : setSearchItem(null);
        params.when ? setTimeFilter(params.when) : setTimeFilter(null);
        params.source ? setSourceItem(params.source) : setSourceItem(null);
        createOffset(params);
    };

    const createOffset = (params) => {
        let offset;
        // if page is not equal 1
        if (params.page) {
            offset = calculateOffset(parseInt(params.page), LIMIT_ITEM_WARNING);
            delete params.page;
        } // page =1 (page load for first time)
        else {
            offset = calculateOffset(1, LIMIT_ITEM_WARNING);
        }
        params["offset"] = offset;
        createApiQueryParams(params);
    };
    const createApiQueryParams = (params) => {
        let updatedQueryParams = apiQueryParams;
        for (let key in params) {
            updatedQueryParams = updateUniqueApiParams(
                `${key}`,
                params[key],
                updatedQueryParams
            );
        }
        setApiQueryParams(updatedQueryParams);
        fetchWarningLetterList(updatedQueryParams);
    };
    const resetPage = () => {
        setCurrentPage(1);
        let offset = calculateOffset(1, LIMIT_ITEM_WARNING);
        let updatedQueryParams = updateUniqueApiParams(
            "offset",
            offset,
            apiQueryParams
        );
        setApiQueryParams(updatedQueryParams);
        return updatedQueryParams;
    };
    const resetAll = () => {
        setCurrentPage(1);
        let offset = calculateOffset(1, LIMIT_ITEM_WARNING);
        let updatedQueryParams = updateUniqueApiParams(
            "offset",
            offset,
            ""
        );
        setTimeFilter(null);
        setSourceItem(null);
        setApiQueryParams("");
        updateUniqueUrlPage(updatedQueryParams);
        getNewsListByUniqueFilters('', '');
    };
    const getNewsListByUniqueFilters = (newkey, newValue) => {
        let updatedQueryParams;
        updatedQueryParams = resetPage();
        // choose new filter
        if (newValue) {
            updatedQueryParams = updateUniqueApiParams(
                newkey,
                newValue,
                updatedQueryParams
            );
            const urlParams = {
                page: 1,
                [newkey]: newValue,
            };
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
        } // choose All item and remove it from url and API
        else {
            // reset page number to 1---------------------------------------
            const urlParams = {page: 1};
            let result = updateUniqueUrlParams(urlParams);
            result = resetQueryParams(newkey, result);
            // remover filter from params of page---------------------------
            updateUniqueUrlPage(result);
            // remove filter from params of api----------------------------
            updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
        }
        setApiQueryParams(updatedQueryParams);
        fetchWarningLetterList(updatedQueryParams);
    };
    const getSortItem = (item) => {
        getNewsListByUniqueFilters("sort", item);
        setSortItem(item);
    };
    const getSearchNews = (searchItem) => {
        getNewsListByUniqueFilters("text", searchItem);
        setSearchItem(searchItem);
    };

    const getTimeFilter = (time) => {
        getNewsListByUniqueFilters("when", time);
        setTimeFilter(time);
    };

    const getSource = (source) => {
        getNewsListByUniqueFilters("source", source);
        setSourceItem(source);
        console.log(source);
    };
    const updateUniqueUrlPage = (result) => {
        history.push({
            pathname: "/warning-letter",
            search: result,
        });
    };
    const getActivatedPage = (page) => {
        if (currentPage !== page) {
            setCurrentPage(page);
            let offset = calculateOffset(page, LIMIT_ITEM_WARNING);
            let updatedQueryParams = updateUniqueApiParams(
                "offset",
                offset,
                apiQueryParams
            );
            setApiQueryParams(updatedQueryParams);
            const urlParams = {page};
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
            fetchWarningLetterList(updatedQueryParams);
        }
    };
    const getNewsList = (data) => {
        console.log(data, "data");
        setWarningLetterList(data.results);
        setCount(data.total_count);
        window.scrollTo(0, 0);
    };

    const fetchWarningLetterList = (updatedQueryParams) => {
        setCount(null);
        sendRequest(
            {url: `${API_NEWS_URL}warning_letters/${updatedQueryParams}`},
            getNewsList
        );
    };
    useEffect(() => {
        splitUrlQueryparams();
        return () => {
            setWarningLetterList([]);
        };
    }, []);

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === "POP") {
                splitUrlQueryparams();
            }
        });
    }, []);

    return (
        <section className={`container  flex-grow ${classes.section}`}>
            <div className="row">
                <div className="col-10">
                    <div className={classes.title}>Warning Letters</div>
                </div>
                <div className={`col-2 ${classes.search}`}>
                    <NewsSearchBox
                        onSearchNews={getSearchNews}
                        searchItem={searchItem}
                        componentUsage="warning_letter"
                    />
                </div>

                <div className="col-xl-9 col-12">

                    {warningLetterList && warningLetterList.length !== 0 && !isLoading && (
                        <div
                            className={`row justify-content-lg-start justify-content-center ${classes["min-h"]}`}
                        >
                            {warningLetterList.map((warningLetter) => {
                                return warningLetter.source === "FDA Warning Letters" ? (
                                    <div
                                        key={warningLetter._id}
                                        className="col-md-6 col-12"
                                    >
                                        <HomeLetterCard warningLetter={warningLetter} type="FDA"/>
                                    </div>
                                ) : warningLetter.source === "FDA 483" ? (
                                    <div key={warningLetter._id} className="col-md-6 col-12">
                                        <HomeLetterCard warningLetter={warningLetter} type="483"/>
                                    </div>
                                ) : warningLetter.source == "UK Warning Letters" ? (
                                    <div
                                        key={warningLetter._id}
                                        className="col-md-6 col-12"
                                    >
                                        <HomeLetterCard warningLetter={warningLetter} type="UK"/>
                                    </div>
                                ) : (
                                    <div
                                        key={warningLetter._id}
                                        className="col-md-6 col-12"
                                    >
                                        <WarningLetterEuDramedItem warningLetter={warningLetter}/>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {isLoading || (warningLetterList && warningLetterList.length === 0 && !isLoading) ? (
                        <div
                            className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
                        >
                            {isLoading && <Loading className={classes.loading}/>}
                            {warningLetterList && warningLetterList.length === 0 && !isLoading && (
                                <NotFoundItems className={classes["mt-50"]} item="News"/>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    {count > LIMIT_ITEM_WARNING && (
                        <Pagination
                            onActivatedPage={getActivatedPage}
                            count={count}
                            limit={LIMIT_ITEM_WARNING}
                            currentPage={currentPage}
                        />
                    )}
                </div>
                <div className="col-xl-3 col-12">
                    <NewsFilterBox
                        onSearchNews={getSearchNews}
                        searchItem={searchItem}
                        onGetTimeFilter={getTimeFilter}
                        initialTimeFilter={timeFilter}
                        onGetSource={getSource}
                        sourceItem={sourceItem}
                        componentUsage="warning-letter"
                    />
                    <NewsFilterBoxAccordion
                        onSearchNews={getSearchNews}
                        searchItem={searchItem}
                        onGetTimeFilter={getTimeFilter}
                        initialTimeFilter={timeFilter}
                        onGetSource={getSource}
                        sourceItem={sourceItem}
                        componentUsage="warning-letter"
                        onReset={resetAll}
                    />
                </div>
            </div>

            {/* <SortBox
        count={count}
        sortItem={sortItem}
        dispatchSortItem={getSortItem}
      /> */}
            {/* <div className={classes["mt-50"]}></div> */}

        </section>
    );
};

export default WarningLetterList;
