import { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classes from "./Header.module.scss";
import logo from "../../../assets/images/logo/pharmatching-logo.svg";
import NavList from "./NavList/NavList";
import NavRegisterLogin from "./NavRegisterLogin/NavRegisterLogin";
import { Link } from "react-router-dom";
import { getAccessToken } from "../../../utils/authentication";
import useHttp from "../../../hooks/use-http";
import {
  API_BASE_URL,
  API_COMPANIES_URL,
  API_SUBSCRIPTION,
} from "../../../constants/API";
import {
  updateUserProfile,
  updateUserCompany,
  updateUserRole,
  updateIsProfileChange,
  updateUserSubscription,
  updateIsSubscriptionChange,
  updateUserAssets,
  updateIsAssetsChnage,
} from "../../../redux/User/userAction";
import { updateIsCompanyChanged } from "../../../redux/Company/companyAction";
import useDetectDisplay from "../../../hooks/use-detect-display";
import ResponsiveSidebarMenu from "../../UI/ResponsiveSidebarMenu/ResponsiveSidebarMenu";
import avatar from "../../../assets/images/Icons/avatar.svg";
import hamburgerMenu from "../../../assets/images/Icons/hamburger menu.svg";

const Header = () => {
  const [isDisplayDesktop, windowWidth] = useDetectDisplay();
  const [receivedApplicationCount, setReceivedApplicationCount] =
    useState(null);
  const [sentRequestCount, setSentRequestCount] = useState(null);
  const [sentApplicationCount, setSentApplicationCount] = useState(null);
  const [draftCount, setDraftCount] = useState(null);
  const [userCompanyId, setUserCompanyId] = useState(null);
  const { sendRequest, error } = useHttp();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isProfileChanged = useSelector((state) => state.user.isProfileChanged);
  const isCompanyChanged = useSelector(
    (state) => state.company.isCompanyChanged
  );
  const isSubscriptionChange = useSelector(
    (state) => state.user.isSubscriptionChange
  );
  const isAssetsChanged = useSelector((state) => state.user.isAssetsChanged);
  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState(null);

  //--------------------------------------------------------------------------------
  const getProfile = useCallback(
    (profile) => {
      console.log('profile:',profile);
      setUserProfile(profile);
      dispatch(updateUserProfile(profile));
      dispatch(updateIsProfileChange(false));
    },
    [dispatch]
  );
  const fecthProfile = useCallback(() => {
    const token = getAccessToken();
    if (isLoggedIn) {
      sendRequest(
        {
          url: `${API_BASE_URL}/api/v1/users/profile/`,
          headers: { Authorization: `Bearer ${token}` },
        },
        getProfile
      );
    }
  }, [getProfile, isLoggedIn, sendRequest]);
  //--------------------------------------------------------------------------------

  const getUserCompany = useCallback(
    (company) => {
      console.log(company);
      setUserCompanyId(company.id);
      dispatch(updateUserCompany(company));
      dispatch(updateIsCompanyChanged(false));     
      if (company.company_type === 0) {
        dispatch(updateUserRole("freelancer"));
      } else if (company.company_type === 1) {
        dispatch(updateUserRole("company"));
      }
    },
    [dispatch]
  );

  const fetchUserCompany = useCallback(() => {
    const token = getAccessToken();
    if (isLoggedIn) {
      sendRequest(
        {
          url: `${API_COMPANIES_URL}/company/user-company/`,
          headers: { Authorization: `Bearer ${token}` },
        },
        getUserCompany
      );
    }
  }, [getUserCompany, isLoggedIn, sendRequest]);

  //--------------------------------------------------------------------------------
  const getCompanyAssets = useCallback((assets) => {
    dispatch(updateUserAssets(assets));
    dispatch(updateIsAssetsChnage(false));
    setReceivedApplicationCount(assets.waiting_received_applications);
    setSentApplicationCount(assets.waiting_sent_applications);
    setSentRequestCount(assets.exact_open_requests);
    setDraftCount(assets.draft_requests);
  },[dispatch]);

  const fetchComapayAssets = useCallback(() => {
    if (isLoggedIn) {
      const token = getAccessToken();
      sendRequest(
        {
          url: `${API_COMPANIES_URL}/company/${userCompanyId}/assets/`,
          headers: { Authorization: `Bearer ${token}` },
        },
        getCompanyAssets
      );
    }
  }, [isLoggedIn, sendRequest, userCompanyId]);

  //--------------------------------------------------------------------------------

  const getCurrentSubscription = useCallback(
    (currentSub) => {
      dispatch(updateUserSubscription(currentSub));
      dispatch(updateIsSubscriptionChange(false));
    },
    [dispatch]
  );

  const fetchCurrentSubscription = useCallback(() => {
    const token = getAccessToken();
    if (isLoggedIn) {
      sendRequest(
        {
          url: `${API_SUBSCRIPTION}/current-subscription/`,
          headers: { Authorization: `Bearer ${token}` },
        },
        getCurrentSubscription
      );
    }
  }, [getCurrentSubscription, isLoggedIn, sendRequest]);

  //--------------------------------------------------------------------------------

  // call neccessary user API after component rendered
  useEffect(() => {
    fecthProfile();
    fetchUserCompany();
    fetchCurrentSubscription();
    fetchComapayAssets();
  }, [fecthProfile, fetchCurrentSubscription, fetchUserCompany,fetchComapayAssets]);

  // useEffect(() => {
  //   if (userCompanyId) {
  //     fetchComapayAssets();
  //   }
  // }, [fetchComapayAssets, userCompanyId]);
  useEffect(() => {    
    if(isAssetsChanged)
      fetchComapayAssets();    
  },[fetchComapayAssets,isAssetsChanged]);

  // call  profile API from the other components------------------------------------
  useEffect(() => {
    if (isProfileChanged) {
      fecthProfile();
    }
    if (isCompanyChanged) {
      fetchUserCompany();
    }
  }, [fecthProfile, fetchUserCompany, isCompanyChanged, isProfileChanged]);

  // call  Subscription API from the other components------------------------------------

  useEffect(() => {
    if (isSubscriptionChange) {
      fetchCurrentSubscription();
    }
  }, [fetchCurrentSubscription, isSubscriptionChange]);

  // handle ERRORs--------------------------------------------------------------------
  useEffect(() => {
    if (error && error.status === 404) {
      dispatch(updateUserRole("guest"));
    }
  }, [dispatch, error]);

  return (
    <header className={classes.header}>
      <nav className={`${classes.navbar} navbar navbar-expand-lg`}>
        <div className="container">
          <Link className="navbar-brand me-0" to="/">
            <img
              className={classes["header-logo"]}
              src={logo}
              alt="pharmatching"
            />
          </Link>
          <div>
            {isLoggedIn && userProfile && !isDisplayDesktop && (
              <div className="d-inline-block">
                <button
                  className={`${classes["profile-btn"]} btn dropdown-toggle`}
                  type="button"
                  id="dropdownMenu"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div
                    style={{
                      backgroundImage: `url(${
                        userProfile.image ? userProfile.image.file : avatar
                      })`,
                    }}
                    className={classes.avatar}
                  ></div>
                </button>
                <ResponsiveSidebarMenu
                  profile={userProfile}
                  windowWidth={windowWidth}
                  receivedApplicationCount={receivedApplicationCount}
                  sentApplicationCount={sentApplicationCount}
                  sentRequestCount={sentRequestCount}
                  draftCount={draftCount}
                />
              </div>
            )}
            <button
              className={`${classes["navbar-toggler"]} navbar-toggler btn`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              data-testid="hamburger-menu"
            >
              <img
                src={hamburgerMenu}
                alt="humburger menu"
                className={`${classes.menu}`}
              />
            </button>
          </div>
          <div
            className="collapse navbar-collapse"
            id="navbarNav"
            data-testid="navbar-nav"
          >
            <NavList />
            <NavRegisterLogin />
            {isLoggedIn && userProfile && isDisplayDesktop && (
              <div className="position-relative d-inline-block">
                <button
                  className={`${classes["profile-btn"]} btn dropdown-toggle`}
                  type="button"
                  id="dropdownMenu"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div
                    style={{
                      backgroundImage: `url(${
                        userProfile.image ? userProfile.image.file : avatar
                      })`,
                    }}
                    className={classes.avatar}
                  ></div>
                </button>
                <ResponsiveSidebarMenu
                  profile={userProfile}
                  responsiveClassName={classes["desktop-menu"]}
                  receivedApplicationCount={receivedApplicationCount}
                  sentApplicationCount={sentApplicationCount}
                  sentRequestCount={sentRequestCount}
                  draftCount={draftCount}
                />
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
