import { useState, useCallback, useEffect, Fragment } from "react";
import { useParams } from "react-router";
import classes from "./RequestView.module.scss";
import RequesViewSummary from "../../components/RequestViewSummary/RequstViewSummary";
import RequestViewHeader from "../../components/RequestViewHeader/RequestViewHeader";
import RequestViewDetails from "../../components/RequestViewDetails/RequestViewDetails";
import useHttp from "../../hooks/use-http";
import { API_REQUEST_URL } from "../../constants/API";
import DetailPageView from "../../components/DetailPageView/DetailPageView";
import Loading from "../../components/UI/Loading/Loading";
import { getAccessToken } from "../../utils/authentication";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseRequestModal from "../../components/UI/CloseRequestModal/CloseRequestModal";
import { createRandomId } from "../../utils/common";
import { addToast } from "../../redux/Toast/toastAction";
import RequestViewExpertise from "../../components/RequestViewExpertise/RequestViewExpertise";

const RequestView = () => {
  const [requestInfo, setRequestInfo] = useState(null);
  const {userCompany } = useSelector(
    (state) => state.user
  );
  const { sendRequest, error, isLoading } = useHttp();
  const [isDisplayModal, setIsDisplayModal] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const userSubscription = useSelector((state) => state.user.userSubscription);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.user);
  const getRequestInfo = (data) => {
    setRequestInfo(data);
    console.log("data:", data);
    window.scrollTo(0, 0);
  };

  const fetchRequestInfo = useCallback(() => {

    if (isLoggedIn) {
      const token = getAccessToken();
      sendRequest({
        url: `${API_REQUEST_URL}${id}`,
        headers: { Authorization: `Bearer ${token}` }
      }
        , getRequestInfo);
    }
    else {
      sendRequest({
        url: `${API_REQUEST_URL}${id}`,
        //headers: { Authorization: `Bearer ${token}` }
      }
        , getRequestInfo);
    }

  }, [id, sendRequest, isLoggedIn]);

  //------------------------------------------------------------------------------------------

  const confirmDeleteRequest = () => {
    setIsDisplayModal(true);
  };

  //------------------------------------------------------------------------------------------
  const getClosedRequestRes = (data) => {
    console.log(data);
    history.push("/dashboard/sent-requests");
  };

  const closeRequest = (arg) => {
    setIsDisplayModal(false);
    const closeReq = {
      close_reason: parseInt(arg.data),
      status: 5,
    };
    if (arg.type === "confirm") {
      const token = getAccessToken();
      sendRequest(
        {
          url: `${API_REQUEST_URL}${id}`,
          body: closeReq,
          headers: { Authorization: `Bearer ${token}` },
          method: "PATCH",
        },
        getClosedRequestRes
      );
    }
  };
  //------------------------------------------------------------------------------------------

  const displayNoLoginToast = useCallback(() => {
    dispatch(
      addToast({
        id: createRandomId(),
        status: "error",
        description: "Unfortunately, you have not subscribed to Pharmatching and cannot access the global market yet. We will forward you to our Solution Manager now.",
      })
    );
  }, [dispatch]);
  const displayNoSubscriptiontToast = useCallback(() => {
    dispatch(
      addToast({
        id: createRandomId(),
        status: "error",
        description: "There are no available Applications in your Profile, but you are only 1 click away to access the global market again. We will forward you to our Solution Manager now and inform your Pharmatching Company Admins about the depleted Company Inventory.",
      })
    );
  }, [dispatch]);
  const sendApplicationHandler = (str) => {
    console.log(str);
    console.log(userSubscription, "userSubscription");

    if (str === "no login" || str === "no company") {
      displayNoLoginToast();
      history.push("/subscription");
    }
    if (str === "has company") {
      if (
        userSubscription &&
        userSubscription.is_active
      ) {
        history.push(`/create-application/${id}`);
      }
      if ((!userSubscription) || (userSubscription && !userSubscription.is_active)) {
        displayNoSubscriptiontToast();
        history.push("/subscription");
      }
    }
  };

  useEffect(() => {
    fetchRequestInfo();
  }, [fetchRequestInfo]);

  return (
    <Fragment>
      {isDisplayModal && <CloseRequestModal onConfirm={closeRequest} />}
      {isLoading && <Loading className={classes.loading} />}
      {requestInfo && !isLoading && (
        <Fragment>
          <RequestViewHeader
            requestInfo={requestInfo}
            onConfirmDelete={confirmDeleteRequest}
            onSendApplicationHandler={sendApplicationHandler}
            userSubscription={userSubscription}
          />
          <section
            className={`container  flex-grow position-relative ${classes.section}  ${classes["min-h"]}`}
          >
            <div className="row justify-content-center">
              {/* <div className="col-xl-4 col-lg-5 col-md-10 col-12">
                <RequesViewSummary
                  requestInfo={requestInfo}
                  onConfirmDelete={confirmDeleteRequest}
                  onSendApplicationHandler={sendApplicationHandler}
                  userSubscription={userSubscription}
                />
              </div> */}
              {
                requestInfo && userCompany && userCompany.id === requestInfo.company ?
                    <div className="col-12">
                <RequestViewDetails
                  requestInfo={requestInfo}
                  userCompany={userCompany}
                />
                {/* <DetailPageView
                  pageViewItem="request"
                  detailPageInfo={requestInfo}
                /> */}
              </div> :
                    <Fragment>
                    <div className="col-lg-7 col-12">
                <RequestViewDetails
                  requestInfo={requestInfo}
                />
                {/* <DetailPageView
                  pageViewItem="request"
                  detailPageInfo={requestInfo}
                /> */}
              </div>
              <div className="col-lg-5 col-12 mt-4 mt-lg-0">
                <RequestViewExpertise
                  requestInfo={requestInfo}
                />

              </div>
                    </Fragment>


              }


            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default RequestView;
