import React, {Fragment} from "react";
import classes from "./SolutionButton.module.scss";

const SolutionButton = ({text, onClickHandler, isDisabled, className}) => {
    return (
        <div className={classes["solution-button"]}>
            <Fragment>
                <div
                    className={`${className} ${classes["select-btn"]} ${ isDisabled && classes.disable}`}
                    onClick={onClickHandler}
                    aria-disabled={isDisabled}
                >
                    {text}
                </div>
            </Fragment>

        </div>
    )
};

export default SolutionButton;
