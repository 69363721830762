import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback, useMemo } from "react";
import classes from "./CreateRequest.module.scss";
import Card from "../../components/UI/CardV2/CardV2";
import SimpleInput from "../../components/UI/SimpleInput/SimpleInput";
import SingleTextAreaWithWordCounter from "../../components/UI/SingleTextAreaWithWordCounter/SingleTextAreaWithWordCounter";
import DropDownInput from "../../components/UI/DropDownInput/DropDownInput";
import AttachFileInput from "../../components/UI/AttachFileInput/AttachFileInput";
import DatePickerDueDateSimple from "../../components/UI/DatePickerDueDateSimple/DatePickerDueDateSimple";
import { useForm } from "react-hook-form";
import { API_TAGS_URL } from "../../constants/API";
import { API_REQUEST_URL } from "../../constants/API";
import SearchableDropdownFilter from "../../components/UI/SearchableDropdownFilter/SearchableDropdownFilter";
import SelectUnSelectInterests from "../../components/SelectUnSelectInterests/SelectUnSelectInterests";
import RequestSize from "../../components/RequestSize/RequestSize";
import useHttp from "../../hooks/use-http";
import { useInterests } from "../../hooks/use-interests";
import { getAccessToken } from "../../utils/authentication";
import { addToast } from "../../redux/Toast/toastAction";
import Loading from "../../components/UI/Loading/Loading";
import { API_SUBSCRIPTION } from "../../constants/API";
import { createRandomId } from "../../utils/common";
import { updateIsAssetsChnage } from "../../redux/User/userAction";




const CreateRequest = () => {


    const { sendRequest, error, isLoading } = useHttp();
    const [reuiredItem, setReuiredItem] = useState(true);
    const dispatch = useDispatch();
    let history = useHistory();
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const [mySolution, setMySolution] = useState([]);
    //---------region and country and specialities consts
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [specialities, setSpecialities] = useState([]);

    const [requestStatus, setRequestStatus] = useState(3);

    const getRegionsList = (regions) => {
        setRegions(regions);
    };

    const getCountriesList = (countries) => {
        setCountries(countries);
    };

    const getSpecialitiesList = (specialities) => {
        setSpecialities(specialities);
    };
    const fetchRegions = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/regions/`,
            },
            getRegionsList
        );
    }, [sendRequest]);

    const fetchCountries = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/countries/`,
            },
            getCountriesList
        );
    }, [sendRequest]);

    const fetchSpecialities = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/specialities/`,
            },
            getSpecialitiesList
        );
    }, [sendRequest]);
    //------key service category consts
    const [serviceCategories, setServiceCategories] = useState([]);
    const {
        handleServiceCategories,
        handleKeyServices,
        handleRegions,
        handleCountries,
        handleSpecialities,
        removeSelectedCategories,
        removeSelectedKeyServices,
        removeSelectedRegion,
        removeSelectedCountries,
        removeSelectedSpecialities,
        interestItems,
        detectIsSubmitForm,
    } = useInterests(
        useMemo(() => [], []),
        useMemo(() => [], []),
        useMemo(() => [], []),
        useMemo(() => [], []),
        useMemo(() => [], []),
        serviceCategories,
        regions,
        countries,
        specialities,
        "request"
    );

    //------get categories
    const getServiceCategories = (serviceCategories) => {
        setServiceCategories(serviceCategories);
    };
    const fetchCategories = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/service-categories/`,
            },
            getServiceCategories
        );
    }, [sendRequest]);

    //------get general info
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        setError,
    } = useForm({

    });



    //get company solution------------------------------------------------------------------------------------------

    const getMySolution = (data) => {
        console.log(data);
        if (!data.is_active || !data.remaining_requests > 0)
            history.push("/subscription");
        setMySolution(data);
    };

    const fetchMySolution = useCallback(() => {

        if (isLoggedIn || getAccessToken()) {
            const token = getAccessToken();
            sendRequest({
                url: `${API_SUBSCRIPTION}/current-subscription/`,
                headers: { Authorization: `Bearer ${token}` },
            }, getMySolution);
        }
        else
            history.push("/subscription");




    }, [sendRequest, isLoggedIn]);





    // get request size-----------------------------
    const [selectedRequestSize, setSelectedRequestSize] = useState({});
    const [requestSizeList, setRequestSizeList] = useState([]);
    const getRequestSizeList = (data) => {
        setRequestSizeList(data);
        setSelectedRequestSize(data[0]);

    };
    const fetchRequestSizeList = useCallback(() => {
        sendRequest({
            url: `${API_TAGS_URL}/request-sizes/`,
        }, getRequestSizeList);
    }, [sendRequest]);
    const getSelectedRequestSize = (requestSize) => {

        setSelectedRequestSize(requestSize);


    };
    // create request object----------------------------------------------------------------------

    const setCreateRequestRequest = (submittedForm) => {
        console.log(submittedForm);
        return {
            title: submittedForm.title,
            content: submittedForm.description,
            specialities: [submittedForm.lifeScienceSector],
            service_categories: interestItems.selectedCategoriesId,
            key_services: interestItems.selectedKeyServicesId,
            countries: interestItems.selectedCountriesId,
            regions: interestItems.selectedRegionId,
            due_date: submittedForm.duedate,
            request_size: selectedRequestSize.id


        };
    };


    //------------------------------------------------------------------------------------------
    const displayCreateDraftToast = useCallback(() => {
        dispatch(
            addToast({
                id: createRandomId(),
                status: "success",
                description: "Your request was saved as draft.",
            })
        );
    }, [dispatch]);

    const getCreatedRequestRes = (res) => {
        dispatch(updateIsAssetsChnage(true));
        if (requestStatus == 3)
            history.push("/request?page=1&ordering=-created_at");
        else {
            displayCreateDraftToast();
            history.push("/dashboard/all-drafts?page=1&ordering=-created_at");
        }
        // uploadCreateRequestFiles(res.id);
    };

    const sumbitCreateRequest = (submittedForm) => {
        const token = getAccessToken();
        const createRequestRequest = setCreateRequestRequest(submittedForm);
        console.log(createRequestRequest);
        const formData = new FormData();
        if (submittedForm.attachFiles)
            formData.append("file", submittedForm.attachFiles);
        if (submittedForm.duedate)
            formData.append("due_date", submittedForm.duedate);
        formData.append("title", submittedForm.title);
        formData.append("request_size", selectedRequestSize.id);
        formData.append("content", submittedForm.description);
        formData.append("status", requestStatus);
        let categoryArray = interestItems.selectedCategoriesId.map((i) => Number(i));
        let keyServiceArray = interestItems.selectedKeyServicesId.map((i) => Number(i));
        let regionsArray = interestItems.selectedRegionId.map((i) => Number(i));
        let countrieArray = interestItems.selectedCountriesId.map((i) => Number(i));
        let specialitiesArray = interestItems.selectedSpecialitiesId.map((i) => Number(i));
        for (var i = 0; i < categoryArray.length; i++) {
            formData.append('service_categories', categoryArray[i]);
        }
        for (var i = 0; i < keyServiceArray.length; i++) {
            formData.append('key_services', keyServiceArray[i]);
        }
        for (var i = 0; i < regionsArray.length; i++) {
            formData.append('regions', regionsArray[i]);
        }
        for (var i = 0; i < countrieArray.length; i++) {
            formData.append('countries', countrieArray[i]);
        }
        for (var i = 0; i < specialitiesArray.length; i++) {
            formData.append('specialities', specialitiesArray[i]);
        }
        sendRequest(
            {
                url: `${API_REQUEST_URL}`,
                method: "POST",
                body: formData,
                headers: { Authorization: `Bearer ${token}` },
            },
            getCreatedRequestRes
        );
    };
    // upload file and company picture after creating company(second call)-------------------------

    //------------------------------------------------------------------------------------------
    useEffect(() => {
        console.log(error);
        if (error && error.status === 400) {
            error.data.errors.title &&
                setError("name", { type: "custom", message: error.data.errors.title });
            error.data.errors.owner_email &&
                setError("email", {
                    type: "custom",
                    message: error.data.errors.owner_email,
                });
            dispatch(
                addToast({
                    id: createRandomId(),
                    status: "error",
                    description: 'An error happend',
                })
            );
        }
    }, [dispatch, error, setError]);

    useEffect(() => {
        fetchMySolution();
        fetchCategories();
        fetchRequestSizeList();
        fetchRegions();
        fetchCountries();
        fetchSpecialities();
    }, [fetchMySolution, fetchCategories, fetchRequestSizeList, fetchRegions, fetchCountries, fetchSpecialities]);


    return (
        <section className={classes.section}>
            {isLoading && mySolution.length === 0 && (
                <div
                    className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
                >
                    <Loading className={classes.loading} />
                </div>
            )}
            {(!isLoading || mySolution.length > 0) && (
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-xl-8 col-lg-11 col-12">

                            <Card className={classes["create-request-card"]}>
                                <div className={`${classes.title}`}>
                                    <span>Send Request</span>
                                </div>
                                <form onSubmit={handleSubmit(sumbitCreateRequest)}>
                                    <div className="row">
                                        <div className="col-lg-8 col-12">
                                            <SimpleInput
                                                validation={{
                                                    required: reuiredItem,
                                                    maxLength: {
                                                        value: 200,
                                                        message: 'This value is too long. It should have 200 characters or fewer.' // JS only: <p>error message</p> TS only support string
                                                    }
                                                }}
                                                input={{
                                                    type: "text",
                                                    label: "Title*",
                                                    name: "title",
                                                    placeholder: "Title",
                                                    id: "Title",
                                                    autoComplete: "off",
                                                }}
                                                errors={errors}
                                                register={register}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-12 mb-4">
                                            <DatePickerDueDateSimple
                                                title="Due Date:"
                                                validation={{
                                                    required: reuiredItem,
                                                }}
                                                input={{
                                                    type: "text",
                                                    name: "duedate",
                                                }}
                                                register={register}
                                                errors={errors}
                                                setValue={setValue}
                                                getValues={getValues}
                                                previewdValue={null}
                                                placeHolder="Select Due Date"

                                            />
                                        </div>
                                    </div>
                                    <SingleTextAreaWithWordCounter
                                        validation={{
                                            required: reuiredItem,
                                            validate: {
                                                positive: v => v.split(' ').length > 49 || '' || !reuiredItem,
                                            }
                                        }}
                                        input={{
                                            type: "text",
                                            label: "Description*",
                                            name: "description",
                                            placeholder: "Description",
                                            autoComplete: "off",
                                            //onKeyUp: () => {alert("ads")},

                                        }}
                                        errors={errors}
                                        register={register}
                                        onKeyUpHandler={() => { }}
                                    />




                                </form>


                                <div className={`${classes["select-category"]} h-70 col-12`}>
                                    <SearchableDropdownFilter
                                        className="w-100"
                                        filterName="Categories"
                                        initialFilters={interestItems.selectedCategoriesId}
                                        filterList={serviceCategories}
                                        dispatchFilterList={handleServiceCategories}
                                        isSelectedItem={interestItems.isSelectedCategories}
                                        isDisabled={false}
                                        errorMessage={interestItems.categoriesErrorMessage}
                                    />
                                </div>
                                <div className="col-12">
                                    <SelectUnSelectInterests
                                        title="Categories"
                                        bgColor="#7894BC"
                                        selectedItems={interestItems.selectedCategories}
                                        removeSelectedItem={removeSelectedCategories}
                                        color="#FFF"
                                    />
                                </div>
                                <div className={`h-70 col-12`}>
                                    <SearchableDropdownFilter
                                        className="w-100"
                                        filterName="Key Services"
                                        initialFilters={interestItems.selectedKeyServicesId}
                                        filterList={interestItems.keyServices}
                                        dispatchFilterList={handleKeyServices}
                                        isSelectedItem={interestItems.isSelectedKeyServices}
                                        isDisabled={interestItems.isDiabled}
                                        errorMessage={interestItems.keyServiceErrorMessage}
                                    />
                                </div>
                                <div className="col-12">
                                    <SelectUnSelectInterests
                                        bgColor="#D3F2FA"
                                        selectedItems={interestItems.selectedKeyServices}
                                        removeSelectedItem={removeSelectedKeyServices}
                                        color="#1E4C90"
                                    />
                                </div>
                                <div className={`h-70 col-12`}>
                                    <SearchableDropdownFilter
                                        className="w-100"
                                        filterName="Life Science Sector"
                                        initialFilters={interestItems.selectedSpecialitiesId}
                                        filterList={specialities}
                                        dispatchFilterList={handleSpecialities}
                                        isSelectedItem={interestItems.isSelectedSpecialities}
                                        isDisabled={false}
                                        errorMessage={interestItems.specialitiesErrorMessage}
                                    />
                                </div>
                                <div className="col-12">
                                    <SelectUnSelectInterests
                                        bgColor="#E4EAF5"
                                        selectedItems={interestItems.selectedSpecialities}
                                        removeSelectedItem={removeSelectedSpecialities}
                                        color="#013480"
                                    />
                                </div>
                                <div className={`h-70 col-12`}>
                                    <SearchableDropdownFilter
                                        className="w-100"
                                        filterName="Regions"
                                        initialFilters={interestItems.selectedRegionId}
                                        filterList={regions}
                                        dispatchFilterList={handleRegions}
                                        isSelectedItem={interestItems.isSelectedRegion}
                                        isDisabled={false}
                                    />
                                </div>
                                <div className="col-12">
                                    <SelectUnSelectInterests
                                        bgColor="#FFF8E6"
                                        selectedItems={interestItems.selectedRegion}
                                        removeSelectedItem={removeSelectedRegion}
                                        color="#664900"
                                    />
                                </div>
                                <div className={`h-70 col-12`}>
                                    <SearchableDropdownFilter
                                        className="w-100"
                                        filterName="Countries"
                                        initialFilters={interestItems.selectedCountriesId}
                                        filterList={countries}
                                        dispatchFilterList={handleCountries}
                                        isSelectedItem={interestItems.isSelectedCountries}
                                        isDisabled={false}
                                    />
                                </div>
                                <div className="col-12">
                                    <SelectUnSelectInterests
                                        bgColor="#E4EAF5"
                                        selectedItems={interestItems.selectedCountries}
                                        removeSelectedItem={removeSelectedCountries}
                                        color="#013480"
                                    />
                                </div>
                                <form onSubmit={handleSubmit(sumbitCreateRequest)}>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <div className={classes.fileInputButton}>
                                                <AttachFileInput
                                                    validation={{
                                                        required: false,
                                                    }}
                                                    input={{
                                                        type: "file",
                                                        name: "attachFiles",
                                                    }}
                                                    register={register}
                                                    errors={errors}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                    placeHolderText="Feel free to upload one PDF file to provide more information and to increase the quality of your request."
                                                // previewdValue={}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 col-12 mt-3 mt-md-0 float-right" >
                                            <div className={classes.DueDate}> */}

                                        {/* <span className={classes["due-date-notice"]}>Every Request must be open for applications for at least 8 weeks.</span>  */}
                                        {/* </div>

                                        </div> */}
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className={classes["request-size-title"]}>Request Size</h3>
                                            <p className={classes.firstText}>
                                                Please provide a realistic estimation of the Project Size based on available budgets or forecasts. Serious service provider and bigger companies will only cooperate if all request information match realistic estimations.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center">
                                        {requestSizeList.map((requestSize) => (
                                            <div key={requestSize.id} className={`${classes["col-lg-equal"]} col-md-3 col-sm-6 col-6 mt-4 text-center`}>
                                                <RequestSize
                                                    requestSize={requestSize}
                                                    dispatchSelectedRequest={getSelectedRequestSize}
                                                    selectedId={selectedRequestSize.id}
                                                />
                                            </div>

                                        ))}
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <p className={classes.congratulationText}>
                                                Congratulations! You will save your company a lot of commissioning fees.<br />
                                                Project broker or recruitment companies charge on average 30% of the project costs as commissioning fees, whereas Pharmatching provides solutions for projects for an average of only 3%, if a successful Matchmaking was performed.
                                            </p>
                                        </div>
                                    </div>




                                </form>
                            </Card>
                            <form onSubmit={handleSubmit(sumbitCreateRequest)}>
                                <div className="row">
                                    <div className="col-md-4">

                                    </div>
                                    <div className="col-md-5 text-end">
                                        <button
                                            onClick={
                                                () => {
                                                    setReuiredItem(false);
                                                    setRequestStatus(1);
                                                }}
                                            className={`btn ${classes.draftButton}`}
                                            type="submit"
                                        >
                                            Save as Draft
                                        </button>
                                    </div>
                                    <div className="col-md-3 text-end">
                                        <button
                                            onClick={
                                                () => {
                                                    detectIsSubmitForm();
                                                    setReuiredItem(true);
                                                    setRequestStatus(3);
                                                }}
                                            className={`btn ${classes.submit}`}
                                            type="submit">
                                            Create Request
                                            <div className={classes["loading-box"]}>
                                                {isLoading && <Loading className={classes.loading} />}
                                            </div>
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default CreateRequest;
