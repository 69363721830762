import { Switch, Route } from "react-router-dom";
import Companies from "./pages/Companies/Companies";
import CompanyView from "./pages/CompanyView/CompanyView";
import NewsList from "./pages/NewsList/NewsList";
import NewsSingle from "./pages/NewsSingle/NewsSingle";
import RequestList from "./pages/RequestList/RequestList";
import RequestView from "./pages/RequestView/RequestView";
import Solutions from "./pages/Solutions/Solutions";
import Authentication from "./pages/Authentication/Authentication";
import Home from "./pages/Home/Home";
import GuestUser from "./pages/GuestUser/GuestUser";
import FreelancerUser from "./pages/FreelancerUser/FreelancerUser";
import Dashboard from "./pages/Dashboard/Dashboard";
import NotFound from "./pages/NotFound/NotFound";
import PaymentResult from "./pages/PaymentResult/PaymentResult";
import MySentApplication from "./pages/MySentApplication/MySentApplication";
import CreateRequest from "./pages/CreateRequest/CreateRequest";
import MyRequestApplications from "./pages/MyRequestApplications/MyRequestApplications";
import EditRequest from "./pages/EditRequest/EditRequest";
import WarningLetterList from "./pages/WarningLetterList/WarningLetterList";
import ApplicationView from "./pages/ApplicationView/ApplicationView";
import CreateApplication from "./pages/CreateApplication/CreateApplication";
import FAQ from "./pages/StaticPages/FAQ/FAQ";
import Imprint from "./pages/StaticPages/Imprint/Imprint";
import AboutUs from "./pages/StaticPages/AboutUs/AboutUs";
import PrivacyPolicy from "./pages/StaticPages/PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./pages/StaticPages/CookiesPolicy/CookiesPolicy";
import LegalDliclaimer from "./pages/StaticPages/LegalDliclaimer/LegalDliclaimer";
import TemrsAndConditions from "./pages/StaticPages/TermsAndConditions/TermsAndConditions";
import Payment from "./pages/Payment/Payment"
import PrivateRoute from "./components/RouteGuard/PrivateRoute";
import GuestRoute from "./components/RouteGuard/GuestRoute";
import WelcomePage from "./pages/WelcomePage/WelcomePage";
import BlogList from "./pages/BlogList/BlogList";
import BlogSingle from "./pages/BlogSingle/BlogSingle";

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path="/request/edit/:id" children={<EditRequest />} />
      <PrivateRoute path="/create-request" children={<CreateRequest />} />
      <PrivateRoute path="/payment-result" children={<PaymentResult />} />
      <Route exact path="/company">
        <Companies />
      </Route>
      <Route path="/company/:id">
        <CompanyView />
      </Route>
      <Route exact path="/news">
        <NewsList />
      </Route>
       <Route exact path="/blogs">
        <BlogList />
      </Route>
        <Route path="/blogs/:id">
        <BlogSingle />
      </Route>
      <Route exact path="/warning-letter">
        <WarningLetterList />
      </Route>
      <Route path="/news/:id">
        <NewsSingle />
      </Route>
      <Route exact path="/request">
        <RequestList />
      </Route>
      <Route path="/request/:id">
        <RequestView />
      </Route>
      <PrivateRoute path="/sent-applications/:id" children={<MySentApplication />} />
      <PrivateRoute path="/request-applications/:id" children={<MyRequestApplications />} />

      <Route path="/application-view/:id">
        <ApplicationView />
      </Route>
      <PrivateRoute path="/create-application/:id" children={<CreateApplication />} />

      <Route exact path="/subscription">
        <Solutions />
      </Route>
      <GuestRoute path="/authentication" children={<Authentication />} />
      {/* <Route path="/authentication">
        <Authentication />
      </Route> */}
      <Route path="/freelancer" >
        <FreelancerUser />
      </Route>
      <Route path="/guest">
        <GuestUser />
      </Route>


      <PrivateRoute path="/dashboard" children={<Dashboard />} />
      {/* <Route path="/dashboard">
        <Dashboard />
      </Route> */}

      <PrivateRoute path="/payment/:id" children={<Payment />} />

      {/* Welcome page */}
      <Route path="/welcome-pharmatching">
        <WelcomePage />
      </Route>
      {/* static pages */}
      <Route path="/faq">
        <FAQ />
      </Route>
      <Route path="/imprint">
        <Imprint />
      </Route>
      <Route path="/about-us">
        <AboutUs />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/cookies-policy">
        <CookiesPolicy />
      </Route>
      <Route path="/legal-dliclaimer">
        <LegalDliclaimer />
      </Route>
      <Route path="/terms-conditions">
        <TemrsAndConditions />
      </Route>
      
      {/* end static pages */}
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
