import classes from "./BlogDetail.module.scss";
import Card from "../UI/Card/Card";
import {dateFormmater} from "../../utils/common";
import email from "../../assets/images/Icons/email.svg";
import view from "../../assets/images/Icons/view.svg";
import shareXing from "../../assets/images/Icons/ShareXing.svg";
import shareTwitter from "../../assets/images/Icons/ShareTwitter.svg";
import shareLinkedIn from "../../assets/images/Icons/ShareLinkedIn.svg";

const BlogDetail = ({blogId, blog}) => {
    let srcLogo = blog.image ? blog.image : "";

    return (
        <Card className={classes.card}>
            <div className={`${classes["blog-header"]}`}>
                <div className={classes["header-title"]}>
                    <div className={classes["header-title-top"]}>
                        <h4 className={classes["blog-title"]}>{blog.title}</h4>
                        <span className={classes["blog-date"]}>
                            {blog.publish_date && dateFormmater(blog.publish_date)}
                        </span>
                    </div>
                    <div className={classes["header-title-bottom"]}>
                        <p className={classes["description"]}>{blog.description}</p>
                        <div className='row mb-50'>
                            <div className={`col-6 ${classes["views-col"]}`}>
                                <div className={classes['views']}>
                                    <img src={view} alt="Views"/> {blog.views}
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className={classes["media-box"]}>
                                    <ul className={classes["media-list"]}>
                                        <li className={classes.item}>
                                            {blog.title && (
                                                <a
                                                    href={`mailto:?subject=${blog.title}&body=${blog.description.substring(
                                                        0,
                                                        250
                                                    )}... Read More : ${window.location.href}`}
                                                    className=""
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={email} alt="shareEmail"/>{" "}
                                                </a>
                                            )}
                                        </li>
                                        <li className={classes.item}>
                                            <a
                                                href={`https://www.xing.com/spi/shares/new?url=http://pharmatching.com/blogs/${blogId}`}
                                                className=""
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={shareXing} alt="shareXing"/>{" "}
                                            </a>
                                        </li>
                                        <li className={classes.item}>
                                            <a
                                                href={`https://twitter.com/intent/tweet?url=http://pharmatching.com/blogs/${blogId}`}
                                                className=""
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={shareTwitter} alt="twitter"/>
                                            </a>
                                        </li>
                                        <li className={classes.item}>
                                            <a
                                                href={`https://www.linkedin.com/shareArticle?url=http://pharmatching.com/blogs/${blogId}`}
                                                className=""
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={shareLinkedIn} alt="linkedin"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={classes["image-wrapper"]}>
                    <div
                        style={{backgroundImage: `url(${srcLogo})`}}
                        className={classes["blog-image"]}
                    >
                    </div>
                </div>
            </div>
            <div className={classes["content"]} dangerouslySetInnerHTML={{__html: blog.content}}/>
        </Card>
    );
};

export default BlogDetail;
