import classes from "./CompanyInvites.module.scss";
import Card from "../UI/CardV2/CardV2";
import SimpleInput from "../UI/SimpleInput/SimpleInput";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import tikIcon from "../../assets/images/Icons/tick.svg";

const CompanyInvites = ({
  invitedUsersList,
  userSubscription,
  onInviteUserToCompany,
  onDeleteInvitation,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (submittedForm) => {
    console.log(submittedForm);
    onInviteUserToCompany(submittedForm.email);
  };
  return (
    <Card className={`container ${classes["company-invites-card"]}`}>
      <div className={classes["company-invites-title"]}>
        <span>Invites</span>
      </div>
      <div className="row mt-30">
        <div className="col-md-6 col-12">
          {userSubscription && userSubscription.is_active && (
            <p className={classes["invite-txt"]}>
              You have <span className={classes["invite-key"]}>{userSubscription.solution.title}</span> and have{" "}
              <span className={classes["invite-key"]}>{userSubscription.remaining_invitations} invites</span> remaining
            </p>
          )}
          {userSubscription && !userSubscription.is_active && (
            <p className={classes["no-subscription"]}>
              Great! Your Company seems to grow! You have reached the maximum
              user count for your subscription, please use our{" "}
              <Link className={classes["link-to-solution"]} to="/subscription">
                {" "}
                Pharmatching Solution Manager
              </Link>{" "}
              to increase your team size.
            </p>
          )}
        </div>
        <div className="col-md-6 col-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="position-relative">
              <SimpleInput
                validation={{
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                }}
                input={{
                  type: "text",
                  label: "Email",
                  name: "email",
                  placeholder: "Email",
                  id: "Email",
                  autoComplete: "off",
                }}
                errors={errors}
                register={register}
              />
              <button className={`btn ${classes.submit}`} type="submit">
                <img src={tikIcon} alt="tick" />
              </button>
            </div>
          </form>
        </div>
        <div className="col-12">
          <div className={classes["seprate-line"]}></div>
          <div className={`table-responsive`}>
            <table
              className={`table table-borderless ${classes["invites-table"]}`}
            >
              <tbody>
                {invitedUsersList.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}.</td>
                    <td>{item.email} </td>
                    {item.status === "sent" && <td>invitation Sent</td>}
                    {item.status === "assigned" && <td>accepted</td>}
                    <td className="text-end">
                      {item.status === "sent" && (
                        <div className="btn-group dropup">
                          <button
                            onClick={() => {
                              onDeleteInvitation(item.id);
                            }}
                            className={`btn ${classes["cencel-btn"]}`}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CompanyInvites;
