import {useEffect, useState, Fragment} from "react";
import classes from "./NewsDetail.module.scss";
import Card from "../UI/Card/Card";
import newsImage from "../../assets/images/pharmatching-news.png";
import email from "../../assets/images/Icons/email.svg";
import shareLinkedIn from "../../assets/images/Icons/ShareLinkedIn.svg";
import shareTwitter from "../../assets/images/Icons/ShareTwitter.svg";
import shareXing from "../../assets/images/Icons/ShareXing.svg";
import thankIcon from "../../assets/images/Icons/like.svg";
import NewsComment from "../NewsComment/NewsComment";
import {dateFormmater} from "../../utils/common";
import useHttp from "../../hooks/use-http";
import {API_FEEDBACK_URL} from "../../constants/API";
import {getAccessToken} from "../../utils/authentication"
import {useSelector} from "react-redux";
import view from "../../assets/images/Icons/view.svg";

const NewsDetail = ({newsId, news, feedbackFilled}) => {
    const {sendRequest, isLoading} = useHttp();
    const [answer, setAnswer] = useState("question");
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);

    let srcLogo = news.image_url ? news.image_url : newsImage;

    const answerHandler = (feedback, feedback_msg = undefined) => {
        const feedback_body = {
            positive: feedback === "Yes" ? true : false,
            feedback_text: feedback_msg,
            news_es_id: newsId,
        };
        handleFeedBack(feedback_body);
        setAnswer("Yes");
    };

    const sendRequestcallBack = (heredata) => {
        console.log(heredata, "News Detail FeedBack Response");
    };

    const handleFeedBack = (feedback_body) => {
        sendRequest(
            {
                url: `${API_FEEDBACK_URL}`,
                method: "POST",
                headers: {Authorization: `Bearer ${getAccessToken()}`},
                body: feedback_body,
            },
            sendRequestcallBack
        );
    };
    useEffect(() => {
        console.log('mynews: ', news);
        console.log('newsId: ', newsId);
    }, [news]);

    const cleanHtmlString = news.text
        .trim()
        .replace(/^```html/, "")
        .replace(/```$/, "");

    return (
        <Card className={classes.card}>
            <div className={`${classes["news-header"]}`}>

                <div className={classes["header-title"]}>
                    <div className={classes["header-title-top"]}>
                        <h4 className={classes["news-title"]}>{news.title}</h4>
                        <a
                            href={news.url}
                            target="_blank"
                            rel="noreferrer"
                            className={classes["news-source"]}
                        >
                            {news.source_name}
                        </a>
                        <span className={classes["news-date"]}>
              {news.date && dateFormmater(news.date)}
            </span>
                    </div>
                    <div className={classes["header-title-bottom"]}>
                        <p className={classes["description"]}>{news.headline}</p>
                        <div className='row mb-50'>
                            <div className={`col-6 ${classes["views-col"]}`}>
                                <div className={classes['views']}>
                                    <img src={view} alt="Views"/> {news.views}
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className={` ${classes["media-box"]}`}>
                                <ul className={classes["media-list"]}>
                                        <li className={classes.item}>
                                            {news.text && (
                                                <a
                                                    href={`mailto:?subject=${news.title}&body=${news.text.substring(
                                                        0,
                                                        250
                                                    )}... Read More : ${window.location.href}`}
                                                    className=""
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={email} alt="shareEmail"/>{" "}
                                                </a>
                                            )}
                                        </li>
                                        <li className={classes.item}>
                                            <a
                                                href={`https://www.xing.com/spi/shares/new?url=http://pharmatching.com/news/news/${newsId}`}
                                                className=""
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={shareXing} alt="shareXing"/>{" "}
                                            </a>
                                        </li>
                                        <li className={classes.item}>
                                            <a
                                                href={`https://twitter.com/intent/tweet?url=http://pharmatching.com/news/news/${newsId}`}
                                                className=""
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={shareTwitter} alt="twitter"/>
                                            </a>
                                        </li>
                                        <li className={classes.item}>
                                            <a
                                                href={`https://www.linkedin.com/shareArticle?url=http://pharmatching.com/news/news/${newsId}`}
                                                className=""
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={shareLinkedIn} alt="linkedin"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes["image-wrapper"]}>
                    <div
                        style={{backgroundImage: `url(${srcLogo})`}}
                        className={classes["news-image"]}
                    >
                    </div>
                </div>
            </div>
            <p className={classes["description"]} dangerouslySetInnerHTML={{ __html: cleanHtmlString }}></p>
            <div className={`row ${classes["news-footer"]}`}>
                <div className={`col-xl-12 col-lg-12 col-12 ${classes.feedback}`}>
                    {answer === "question" && isLoggedIn && !feedbackFilled && (
                        <div className="d-flex align-items-center">
              <span className={classes.question}>
                Do you find this information useful?
              </span>
                            <button
                                onClick={() => answerHandler("Yes")}
                                className={`btn ${classes.yes}`}
                            >
                                Yes
                            </button>
                            <button
                                onClick={() => setAnswer("No")}
                                className={`btn ${classes.no}`}
                            >
                                No
                            </button>
                        </div>
                    )}
                    {((answer === "Yes") || (isLoggedIn && feedbackFilled)) && (
                        <div className="d-flex">
                            <img
                                src={thankIcon}
                                className="align-self-center"
                                alt="thank you"
                            />
                            <span className={classes.thank}>
                Thank you very much for your valuable Feedback which will be
                used to improve our Services for you!
              </span>
                        </div>
                    )}
                </div>

                {answer === "No" && (
                    <div className="col-12 order-2">
                        <NewsComment onSubmitAnswer={answerHandler}/>
                    </div>
                )}
                {/* <div
          className={` col-lg-3 col-sm-6 col-12 text-end ${classes["news-source"]}`}
        >
          <span className={classes.key}>Source link:</span>
          <a
            href={news.source_link}
            target="_blank"
            rel="noreferrer"
            className={classes.value}
          >
            {news.source_name}
          </a>
        </div> */}

            </div>
        </Card>
    );
};

export default NewsDetail;
