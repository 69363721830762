import classes from "./GeneralStatisticsCard.module.scss";
import { SeprateNumberWithComma } from "../../utils/common";

const GeneralStatisticsCard = ({ children, title, value, className }) => {
	return (
		<div className={`${classes["section"]} ${className ?? ""}`}>
			<div className={classes["icon"]}>{children}</div>
			<p>{SeprateNumberWithComma(value)}</p>
			<h2>{title}</h2>
		</div>
	);
};

export default GeneralStatisticsCard;
